import { useQuery } from '@apollo/react-hooks';
import { useCallback, useContext, useMemo } from 'react';
import type { ApolloError } from 'apollo-client';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { ConfluenceEdition } from '@confluence/change-edition';
import { getAGGClient, isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';

import { TrialDetailsQuery } from '../graphql/TrialDetailsQuery.graphql';
import type { TrialDetailsQuery as TrialDetailsQueryType } from '../graphql/__types__/TrialDetailsQuery';
import { CanProductUserAddBilling } from '../graphql/CanProductUserAddBilling.agggraphql';

import { MPU_COHORTS, MPU_QUERY, MPU_STATSIG } from './mpuHookConstants';

export const useCanAddBillingForTrialProductUser = (skip: boolean = false) => {
	const { isSiteAdmin, isAnonymous } = useContext(SPAViewContext);
	const { cloudId, edition, isLoggedIn, accessStatus } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const meetsBaseEligibility =
		!isSiteAdmin &&
		!isAnonymous &&
		isLoggedIn &&
		(accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
			accessStatus === AccessStatus.LICENSED_USE_ACCESS) &&
		(edition === ConfluenceEdition.STANDARD || edition === ConfluenceEdition.PREMIUM) &&
		!skip;

	// 1. get statsig cohort, w/o firing exposure
	const cohort = (
		meetsBaseEligibility
			? FeatureGates.getExperimentValue(
					MPU_STATSIG.EXPERIMENT_NAME,
					MPU_STATSIG.EXPERIMENT_KEY,
					MPU_COHORTS.NOT_ENROLLED,
					{ fireExperimentExposure: false },
				)
			: MPU_COHORTS.NOT_ENROLLED
	) as MPU_COHORTS;

	// 2. determine billing system (both control & variation need to make the Commerce request)
	const {
		data: { license } = { license: null },
		error: trialDetailsError,
		loading: trialDetailsLoading,
	} = useQuery<TrialDetailsQueryType>(TrialDetailsQuery, {
		skip: !meetsBaseEligibility || cohort === MPU_COHORTS.NOT_ENROLLED,
	});

	// 3. if CCP, make commerce query and determine if they meet backend eligibility
	const {
		data: commerceData,
		error: commerceError,
		loading: commerceLoading,
	} = useQuery(CanProductUserAddBilling, {
		client: getAGGClient(),
		variables: {
			cloudId,
			productKey: MPU_QUERY.HAMS_PRODUCT_KEY,
		},
		skip:
			!meetsBaseEligibility ||
			cohort === MPU_COHORTS.NOT_ENROLLED ||
			license?.billingSourceSystem !== 'CCP',
	});

	const errors = [trialDetailsError, commerceError];

	errors
		.filter((error?: ApolloError): error is ApolloError => !!error)
		.forEach((error: ApolloError) => {
			!isErrorMarkedAsHandled(error) && markErrorAsHandled(error);
		});

	if (commerceError) {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'failed',
				actionSubject: 'canProductUserPay',
				source: 'useCanAddBillingForTrialProductUser',
				attributes: {
					edition,
					isSiteAdmin,
				},
			},
		}).fire();
	}
	const { isBillingAdmin, canAddBillingDetails, hasBillingDetails } =
		parseCommerceResponse(commerceData);

	const canAddBillingForTrial =
		meetsBaseEligibility &&
		(cohort === MPU_COHORTS.CONTROL || cohort === MPU_COHORTS.VARIATION) &&
		license?.billingSourceSystem === 'CCP' &&
		canAddBillingDetails &&
		!hasBillingDetails;

	const analyticsAttributes = useMemo(
		() => ({
			mpuCohort: cohort,
			isSiteAdmin,
			isBillingAdmin: canAddBillingForTrial ? isBillingAdmin : undefined,
		}),
		[cohort, isSiteAdmin, canAddBillingForTrial, isBillingAdmin],
	);

	const fireExposure = useCallback(() => {
		if (canAddBillingForTrial) {
			FeatureGates.manuallyLogExperimentExposure(MPU_STATSIG.EXPERIMENT_NAME);

			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'exposed',
					actionSubject: 'feature',
					actionSubjectId: 'editionAwarenessPill',
					source: 'actionMenu',
					attributes: {
						flagKey: MPU_STATSIG.EXPERIMENT_NAME,
						cohort,
						edition,
						...analyticsAttributes,
					},
				},
			}).fire();
		}
	}, [canAddBillingForTrial, createAnalyticsEvent, cohort, edition, analyticsAttributes]);
	return {
		loading: commerceLoading || trialDetailsLoading,
		cohort,
		canAddBillingForTrial,
		isEligible: canAddBillingForTrial,
		isEligibleForControl: canAddBillingForTrial && cohort === MPU_COHORTS.CONTROL,
		isEligibleForVariation: canAddBillingForTrial && cohort === MPU_COHORTS.VARIATION,
		// canAddBillingDetails lets us know that the site is either in trial OR pre-dunning
		//   Then a null trialEndDate means pre-dunning
		isPreDunning: canAddBillingDetails && license?.trialEndDate === null,
		isBillingAdmin,
		hasBillingDetails,
		fireExposure,
		analyticsAttributes,
		commerceError,
	};
};

const parseCommerceResponse = (data: any) => {
	const entitlement = data?.tenantContexts?.[0]?.entitlementInfo?.entitlement;
	const transactionAccount = entitlement?.transactionAccount;
	const invoiceGroup = entitlement?.subscription?.accountDetails?.invoiceGroup;
	const configurePayment = invoiceGroup?.experienceCapabilities?.configurePaymentV2;

	return {
		isBillingAdmin: !!transactionAccount?.isCurrentUserBillingAdmin,
		canAddBillingDetails: !!configurePayment?.isAvailableToUser,
		hasBillingDetails: invoiceGroup?.invoiceable,
	};
};
